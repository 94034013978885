<template>
  <div class="vh-100">
    <div class="authincation h-100">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <h4 class="text-center mb-4">
                      {{ lan.Welcome_to_konferenceBokarna }}
                    </h4>
                    <div>
                      <div class="form-group">
                        <label class="mb-1"><strong>{{ lan.Email }}</strong></label>
                        <input type="email" class="form-control checkk" v-model="email" />
                      </div>


                      <div class="form-group">
                        <label class="mb-3"><strong>{{ lan.Password }}</strong>

                        </label>

                        <div class="pass-code">
                          <input autocomplete="one-time-code" type="password" class="form-control typeChange checkk"
                            v-model="password" />
                          <span @click="typeChange()" toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password pointer"></span>
                        </div>
                      </div>
                      <div class="form-row d-flex justify-content-between mt-4 mb-2">
                        <!-- <div class="form-group">
                                             <div class="custom-control custom-checkbox ml-1">
													<input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
													<label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
												</div>
                                          </div> -->
                        <div class="form-group ml-1">
                          <button data-toggle="modal" data-target="#exampleModal33" style="border: none">
                            {{ lan.Forgot_Password }}
                          </button>
                        </div>
                      </div>
                      <div class="text-center">
                        <button @click="login()" class="btn btn-primary btn-block">
                          <div class="lds-ring" v-show="loginButtonLoaderValue">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <p v-show="!loginButtonLoaderValue">Login</p>
                        </button>
                      </div>
                    </div>
                    <!-- <div class="new-account mt-3">
                      <p>
                        Don't have an account? -->
                    <!-- <a class="text-primary" href="page-register.html"
                          >Sign up</a
                        > -->
                    <!-- </p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- start Modal -->
  <div class="modal fade" id="exampleModal33" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-3 px-3">
          <h5 class="modal-title" id="exampleModalLabel">Forgot Password</h5>
          <button id="Close" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-3 px-3">
          <div class="form-group">
            <label class="mb-2"><strong>Enter Email</strong></label>
            <input type="email" class="form-control mb-1" v-model="forgotEmail" />
          </div>
          <div class="text-right">
            <button type="button" class="btn btn-primary" @click="sendForgetPassword()">
              <div id="lds-ring" class="lds-ring mx-auto" style="display: none;">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span id="Send">Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
// import store from "../../store/index";

export default {
  name: "Login",
  page: {
    title: "Login",
    meta: [{ name: "description", content: "Log in to confrence" }],
  },
  dada() {
    return {
      email: "",
      password: "",
      forgotEmail: "",
      loading: false,
      Send_pas: true,
    };
  },
  computed: {
    ...mapGetters(["loginButtonLoaderValue"]),
    lan(){
      return this.$store.getters.whichLanguage
    },
  },

  mounted() {
    let inputElements = document.getElementsByClassName("checkk");
    for (var i = 0; i < inputElements.length; i++) {
      inputElements[i].addEventListener("keypress", this.handleKeyPress);
    }

  },

  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.login().click();
      }
    },
    typeChange() {
      let typeChange = document.querySelector(".typeChange")
      if (typeChange.type == "password") {
        typeChange.type = "text"
      } else {
        typeChange.type = "password"
      }
    },

    sendForgetPassword() {
      var lds = document.getElementById('lds-ring')
      var Send = document.getElementById('Send')
      Send.style.display = 'none'
      lds.style.display = 'block'
      var formData = new FormData();
      formData.append("email", this.forgotEmail);
      axios
        .post(axios.defaults.baseURL + "forgot-password", formData)
        .then((res) => {
          Send.style.display = 'block'
          lds.style.display = 'none'
          this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          // this.AddToast(this.lan.Reset_Password_link_sent_in_your_Email_address_check_your_mail, " Success ", "success_1");
          console.log("forgot-password res", res);
          document.getElementById('Close').click()
        })
        .catch((err) => {
          Send.style.display = 'block'
          lds.style.display = 'none'
          console.log("forgot-password err", err);
          if (err.response.data.data == 'This email address is not associated with any account') {
            this.AddToast(this.lan.This_email_address_is_not_associated_with_any_account, "Faild", "error_1");
          } else {
            this.AddToast(err.response.data.message, "Faild", "error_1");
          }
        });
    },
    login() {
      this.$store.dispatch("login", {
        username: this.email,
        password: this.password,
      });
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pass-code {
  position: relative;
}

.pass-code span {
  position: absolute;
  right: 10px;
  top: 18px;
}
</style>