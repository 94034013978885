<template>
  <!--**********************************
          Main wrapper start
      ***********************************-->
  <div id="main-wrapper">
    <!--**********************************
    Content body start
***********************************-->

     <div class="content-body pt-0">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.partners[3].commission[0].Commissions}}</h2>
        </div>

        <div class="lds-ellipsis" v-show="value">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div v-if="!value && !newCommissionToReport.length && !upComingCommissions.length && !submittedCommissions.length">
          <span>
            {{ lan?.commission_blank_page_message }}
          </span>
        </div>

        <div class="row mb-5">
          <div v-if="!value && newCommissionToReport.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[3].commission[0].New_commission_to_report}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{ lan.partners[3].commission[0].Opportunity}}</th>
                          <th>{{ lan.partners[3].commission[0].Accounts}}</th>
                          <th>{{ lan.partners[3].commission[0].date}}</th>
                          <th>{{ lan.partners[3].commission[0].Report_status_Commission}}</th>
                        </tr>
                      </thead>
                      <tbody v-for="(data, index) in newCommissionToReport"
                          :key="index"
                          @click="edit(data.commission_id)"
                          class="pointer">
                        <tr class>
                          <td v-if="
                            data.customer_account_detail != ''">
                            {{ data.customer_account_detail[0].name }}
                          </td>
                          <td v-else>
                          </td>
                          <td>{{ data.account_detail.name }}</td>
                          <td>{{ data.conference_end_date }}</td>
                          <td v-if="data.commission_status == 'Submitted'">
                            <span class="badge light badge-success"
                              >{{ data.commission_status}}</span
                            >
                          </td>
                          <td v-else>
                            <span class="badge light badge-info"
                              >{{ data.commission_status}}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div v-if="!value && upComingCommissions.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[3].commission[0].Upcoming_Commission}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{ lan.partners[3].commission[0].Opportunity}}</th>
                          <th>{{ lan.partners[3].commission[0].Accounts}}</th>
                          <th>{{ lan.partners[3].commission[0].date}}</th>
                          <th>{{ lan.partners[3].commission[0].Report_status_Commission}}</th>
                        </tr>
                      </thead>
                      <tbody v-for="(data, index) in upComingCommissions"
                          :key="index"
                          @click="edit(data.commission_id)"
                          class="pointer">
                        <tr>
                          <td v-if="
                            data.customer_account_detail != ''">
                            {{ data.customer_account_detail[0].name }}
                          </td>
                          <td v-else></td>
                          <td>{{ data.account_detail.name }}</td>
                          <td>{{ data.conference_end_date }}</td>
                          <td>
                            <span class="badge light badge-warning"
                              >{{ data.commission_status}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!value && submittedCommissions.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[3].commission[0].Submitted_commissions}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{ lan.partners[3].commission[0].Opportunity}}</th>
                          <th>{{ lan.partners[3].commission[0].Accounts}}</th>
                          <th>{{ lan.partners[3].commission[0].date}}</th>
                          <th>{{ lan.partners[3].commission[0].Report_status_Commission}}</th>
                        </tr>
                      </thead>
                      <tbody v-for="(data, index) in submittedCommissions"
                          :key="index"
                          @click="edit(data.commission_id)"
                          class="pointer">
                        <tr
                          v-if="data.commission_status == 'Submitted'"
                        >
                          <td v-if="
                            data.customer_account_detail != ''">
                            {{ data.customer_account_detail[0].name }}
                          </td>
                          <td v-else>
                          </td>
                          <td>{{ data.account_detail.name }}</td>
                          <td>{{ data.conference_end_date }}</td>
                          <td v-if="data.commission_status == 'Submitted'">
                            <span class="badge light badge-success"
                              >{{ data.commission_status}}</span
                            >
                          </td>
                          <td v-else>
                            <span class="badge light badge-info"
                              >{{ data.commission_status}}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[3].commission[0].Received_Commission}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{ lan.partners[3].commission[0].Opportunity}}</th>
                          <th>{{ lan.partners[3].commission[0].Accounts}}</th>
                          <th>{{ lan.partners[3].commission[0].date}}</th>
                          <th>{{ lan.partners[3].commission[0].Report_status_Commission}}</th>
                        </tr>
                      </thead>
                      <tbody v-for="(data, index) in commissionDetails"
                          :key="index"
                          @click="edit(data.commission_id)"
                          class="pointer">
                        <tr
                          v-if="data.commission_status == 'Submitted'"
                        >
                          <td v-if="
                            data.customer_account_detail != ''">
                            {{ data.customer_account_detail[0].name }}
                          </td>
                          <td v-else>
                          </td>
                          <td>{{ data.account_detail.name }}</td>
                          <td>{{ data.conference_end_date }}</td>
                          <td v-if="data.commission_status == 'Submitted'">
                            <span class="badge light badge-success"
                              >{{ data.commission_status}}</span
                            >
                          </td>
                          <td v-else>
                            <span class="badge light badge-info"
                              >{{ data.commission_status}}</span
                            >
                          </td>
                        </tr>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>
        <div class="col-xl-6 col-xxl-12">
          <div class="row"></div>
        </div>
      </div>
    </div>
    <!--**********************************
    Content body end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Partner Commission",
  data() {
    return {
      commissionDetails: "",
      value: true,
      newCommissionToReport: [],
      upComingCommissions: [],
      submittedCommissions: []
    };
  },

  computed:{
    lan(){
      return this.$store.getters.whichLanguage
    }
  },


  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isPartner) {
  //     this.AddToast("You can't access Partner Portal", " Warning ", "warning_1");
  //     setTimeout(() => {
  //       alert('First logout then Login partner portal.')
  //     this.$router.back()
  //     }, 3000);
  //   }
  // },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user: " + user);
    axios
      .get(axios.defaults.baseURL + "get-commissions/" + user.company , {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.value = false;
        this.commissionDetails = res.data.data;
        console.log("commissionDetails", this.commissionDetails);
        
        // newCommissionToReport and upComingCommissions data start
        for (let i = 0; i < this.commissionDetails.length; i++) {
          let today = new Date();
          let commission =  this.commissionDetails[i];
          let endDate = new Date(commission.conference_end_date);
          if (endDate < today) {
            // Add the commission to the newCommissionToReport array
            this.newCommissionToReport.push(commission);
          }
          else if(commission.commission_status == 'Submitted'){
            this.submittedCommissions.push(commission)
          }
          else if(endDate > today){
            this.upComingCommissions.push(commission)
          }
        }
      console.log("newCommissionToReport", this.newCommissionToReport);
      console.log("upComingCommissions", this.upComingCommissions);
      console.log("submittedCommissions", this.submittedCommissions);
      })
      .catch((err) => {
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });

      
  },
  methods: {
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Partner Edit Commission",params: { id: id }, });
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
