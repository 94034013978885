<template>
  <preLoader />
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->

    <div class="content-body pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[7].Commission[0].New_commission_to_report}}</h2>
        </div>

        <div v-if="!value && !receivedCommissions.length && !pendingCommissions.length && !upComingCommissions.length">
          <span>
            {{ lan?.commission_blank_page_message }}
          </span>
        </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">{{ lan.admin[7].Commission[0].Received_Commission}}</h4>
                  <span>Total: <strong>( {{ receivedCommissions.length }} )</strong></span>
                </div>
                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>{{ lan.admin[0].admin_portal[0].Opportunity}}</th>
                            <th>{{ lan.admin[0].admin_portal[0].Account}}</th>
                            <th>{{ lan.admin[0].admin_portal[0].Date}}</th>
                            <th>{{ lan.admin[7].Commission[0].Report_status_Commission}}</th>
                          </tr>
                        </thead>
                        <tbody v-for="(data, index) in displayed_receivedCommissions"
                            :key="index"
                            @click="edit(data.commission_id)"
                            class="pointer">
                          <tr
                            v-if="data.commission_status == 'Submitted'"
                          >
                          <td>{{data?.commission_id}}</td>
                            <td v-if="
                              data.customer_account_detail != ''">
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <!-- <td></td> -->
                            <td>{{ data.account_detail.name }}</td>
                            <td>{{ data.conference_end_date }}</td>
                            <td>
                              <span class="badge light badge-success"
                                >{{ data.commission_status}}</span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                      <div v-if="receivedCommissions.length > 5" class="text-right">
                        <button @click="toggleViewAll_receivedCommissions" class="view-all-less-btn">{{ showAll_receivedCommissions ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">{{ lan.admin[7].Commission[0].Pending_Commission}}</h4>
                  <span>Total: <strong>( {{ pendingCommissions.length }} )</strong></span>
                </div>
                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>{{ lan.admin[0].admin_portal[0].Opportunity}}</th>
                            <th>{{ lan.admin[0].admin_portal[0].Account}}</th>
                            <th>{{ lan.admin[0].admin_portal[0].Date}}</th>
                            <th>{{ lan.admin[7].Commission[0].Report_status_Commission}}</th>
                          </tr>
                        </thead>
                        <tbody v-for="(data, index) in displayed_pendingCommissions"
                            :key="index"
                            @click="edit(data.commission_id)"
                            class="pointer">
                          <tr
                          >
                          <td>{{data?.commission_id}}</td>
                            <td v-if="
                              data.customer_account_detail != ''">
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else>
                            </td>
                            <td>{{ data.account_detail.name }}</td>
                            <td>{{ data.conference_end_date }}</td>
                            <td v-if="data.commission_status == 'Submitted'">
                              <span class="badge light badge-success"
                                >{{ data.commission_status}}</span
                              >
                            </td>
                            <td v-else>
                              <span class="badge light badge-info"
                                >{{ data.commission_status}}</span
                              >
                            </td>
                          </tr>
                          <div class="lds-ellipsis" v-show="value">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </tbody>
                      </table>
                      <div v-if="pendingCommissions.length > 5" class="text-right">
                        <button @click="toggleViewAll_pendingCommissions" class="view-all-less-btn">{{ showAll_pendingCommissions ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">{{ lan.admin[7].Commission[0].Upcoming_Commission}}</h4>
                  <span>Total: <strong>( {{ upComingCommissions.length }} )</strong></span>
                </div>
                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>{{ lan.admin[0].admin_portal[0].Opportunity}}</th>
                            <th>{{ lan.admin[0].admin_portal[0].Account}}</th>
                            <th>{{ lan.admin[0].admin_portal[0].Date}}</th>
                            <th>{{ lan.admin[7].Commission[0].Report_status_Commission}}</th>
                          </tr>
                        </thead>
                        <tbody v-for="(data, index) in displayed_upComingCommissions"
                            :key="index"
                            @click="edit(data.commission_id)"
                            class="pointer">
                          <tr
                          >
                          <td>{{data?.commission_id}}</td>
                            <td v-if="
                              data.customer_account_detail != ''">
                              {{ data.customer_account_detail[0].name }}
                            </td>
                            <td v-else></td>
                            <td>{{ data.account_detail.name }}</td>
                            <td>{{ data.conference_end_date }}</td>
                            <td>
                              <span class="badge light"
                              :class="data.commission_status == 'New' ? 'badge-success' : 'badge-warning'"
                                >{{ data.commission_status}}</span>
                            </td>
                          </tr>
                        </tbody>
                        <div class="lds-ellipsis" v-show="value">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                      <div v-if="upComingCommissions.length > 5" class="text-right">
                        <button @click="toggleViewAll_upComingCommissions" class="view-all-less-btn">{{ showAll_upComingCommissions ? 'Show Less' : 'Show More' }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-xxl-12">
          <div class="row"></div>
        </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
</div>
</template>
<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "Commission",
  data() {
    return {
      commissionDetails: "",
      value: true,
      receivedCommissions: [],
      pendingCommissions: [],
      upComingCommissions: [],
      showAll_receivedCommissions: false,
      showAll_pendingCommissions: false,
      showAll_upComingCommissions: false,
    };
  },

  computed:{
    lan(){
      return this.$store.getters.whichLanguage
    },
    displayed_receivedCommissions() {
      return this.showAll_receivedCommissions ? this.receivedCommissions : this.receivedCommissions.slice(0, 5);
    },
    displayed_pendingCommissions() {
      return this.showAll_pendingCommissions ? this.pendingCommissions : this.pendingCommissions.slice(0, 5);
    },
    displayed_upComingCommissions() {
      return this.showAll_upComingCommissions ? this.upComingCommissions : this.upComingCommissions.slice(0, 5);
    },

  },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     
    axios
      .get(axios.defaults.baseURL + "commissions", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.commissionDetails = res.data.data;
        console.log("commissionDetails", this.commissionDetails);
        
        // pending commission data start
        for (let i = 0; i < this.commissionDetails.length; i++) {
        let today = new Date();
        let commission =  this.commissionDetails[i];
        let endDate = new Date(commission.conference_end_date);
        if (endDate < today && commission.commission_status != "Submitted") {
          // Add the commission to the pendingCommissions array
          this.pendingCommissions.push(commission);
        }
        else if(commission.commission_status == 'Submitted'){
          this.receivedCommissions.push(commission)
        }
        else if(endDate > today && commission.quote_detail.status == 'Accepted'){
          this.upComingCommissions.push(commission)
        }
        
      }
      this.value = false;
        // pending commission data end
      })
      .catch((err) => {
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });

     
  },
  methods: {
    toggleViewAll_receivedCommissions() {
      this.showAll_receivedCommissions = !this.showAll_receivedCommissions;
    },
    toggleViewAll_pendingCommissions() {
      this.showAll_pendingCommissions = !this.showAll_pendingCommissions;
    },
    toggleViewAll_upComingCommissions() {
      this.showAll_upComingCommissions = !this.showAll_upComingCommissions;
    },
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Commission", params: { id: id }, });
    },
  },
};
</script>
