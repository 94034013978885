<template>
  <!--**********************************
          Main wrapper start
      ***********************************-->
  <div id="main-wrapper">
    <!--**********************************
    Content body start
***********************************-->

    <div class="content-body pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.partners[0].dashboard[0].Quotes}}</h2>
        </div>

        <div class="lds-ellipsis" v-show="value">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div class="row">
          <div v-if="!value && quotesNew.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[0].dashboard[0].Quotes_to_respond_to}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{lan.partners[0].dashboard[0].Quote}} Id</th>
                          <th>{{ lan.partners[1].quote[0].Account}}</th>
                          <th>{{ lan.partners[1].quote[0].date}}</th>
                          <th>{{ lan.partners[1].quote[0].Participants}}</th>
                          <th>{{ lan.partners[1].quote[0].Status}}</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(data, index) in quotesNew"
                        :key="data.quote_id"
                        class="pointer"
                      >
                        <tr
                          v-if="
                            data.status == 'New' ||
                            data.status == 'Saved' || data.status == 'Preliminary Booking'
                          "
                        >
                        <td @click="edit(data.quote_id)">{{ data.quote_id }}</td>
                          <td v-if="
                            data.customer_account_detail && data.customer_account_detail.length" @click="edit(data.quote_id)">
                            {{ data.customer_account_detail[0].name }}
                          </td>
                          <td v-else @click="edit(data.quote_id)"></td>
                          <td @click="edit(data.quote_id)">{{ data.date }}</td>
                          <td @click="edit(data.quote_id)">
                            {{ data.opportunity_detail.number_of_participants }}
                          </td>
                          <td class="pointer" data-toggle="modal" @click="(data.status == 'Preliminary Booking' || data.status == 'Preliminary Booked') ? openPreliminaryBooking(data) : edit(data.quote_id)">
                            <span
                              class="badge light"
                              :class="
                                data.status == 'New'
                                  ? 'badge-success': data.status == 'Preliminary Booking' ? 'badge-success'
                                  : 'badge-warning'
                              "
                              >{{ data.status == 'Preliminary Booking' ? lan.Status.Respond_to_prel_booking : data.status == 'New' ? lan.Status.New : lan.Status.Saved}}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!value && quotesSubmitted.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[1].quote[0].Waiting_for_acceptance}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{lan.partners[0].dashboard[0].Quote}} Id</th>
                          <th>{{ lan.partners[1].quote[0].Account}}</th>
                          <th>{{ lan.partners[1].quote[0].Preliminary_booked}}</th>
                          <th>{{ lan.partners[1].quote[0].date}}</th>
                          <th>{{ lan.partners[1].quote[0].Status}}</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(data, index) in quotesSubmitted"
                        :key="data.quote_id"
                      >
                        <tr v-if="data.status == 'Submitted' || data.status == 'Preliminary Booked' || data.status == 'Preliminary Cancelled' ">
                          <td class="pointer" @click="edit(data.quote_id)">{{ data.quote_id }}</td>
                          <td v-if="
                            data.customer_account_detail && data.customer_account_detail.length" class="pointer" @click="edit(data.quote_id)">
                             <b>{{ data.customer_account_detail[0].name }}</b>
                          </td>
                          <td v-else></td>
                          <td class="pointer" @click="edit(data.quote_id)">{{ data.status == 'Preliminary Booked' ? 'Yes' : 'No' }}</td>
                          <td class="pointer" @click="edit(data.quote_id)">{{ data.date }}</td>
                          <td class="pointer" data-toggle="modal" @click="(data.status == 'Preliminary Booking' || data.status == 'Preliminary Booked') ? openPreliminaryBooking(data) : edit(data.quote_id)">
                            <span
                              class="badge light"
                              :class="
                                data.status == 'Submitted'
                                  ? 'badge-info'
                                  : data.status == 'Preliminary Booked'
                                  ? 'badge-success'
                                  : 'badge-danger'
                              "
                              >{{ data.status == 'Submitted'
                                    ? lan.Status.Submitted
                                    : data.status == 'Preliminary Booked'
                                    ? lan.Status.Preliminary_Booked
                                    : data.status == 'Preliminary Booking'
                                    ? lan.Status.Preliminary_Booking
                                    : lan.Status.Preliminary_Cancelled }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!value && quotesAccepted.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[1].quote[0].Accepted_quotes}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{lan.partners[0].dashboard[0].Quote}} Id</th>
                          <th>{{ lan.partners[1].quote[0].Customer}}</th>
                          <!-- <th>Account</th> -->
                          <th>{{ lan.partners[1].quote[0].date}}</th>
                          <th>{{ lan.partners[1].quote[0].Status}}</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(data, index) in quotesAccepted"
                        :key="data.quote_id"
                        @click="edit(data.quote_id)"
                        class="pointer"
                      >
                        <tr v-if="data.status == 'Accepted'">
                          <td @click="edit(data.quote_id)">{{ data.quote_id }}</td>
                          <td v-if="
                            data.customer_account_detail && data.customer_account_detail.length">
                             <b>{{ data.customer_account_detail[0].name }}</b>
                          </td>
                          <td v-else></td>
                          <!-- <td>{{ data.account_detail.name }}</td> -->
                          <td>{{ data.date }}</td>
                          <td>
                            <span
                              class="badge light"
                              :class="
                                data.status == 'Accepted'
                                  ? 'badge-success': 'badge-warning'
                              "
                              >{{ data.status == 'Accepted' ? lan.Status.Accepted : data.status }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!value && quotesLost.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[1].quote[0].Quote_lost}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{lan.partners[0].dashboard[0].Quote}} Id</th>
                          <th>{{ lan.partners[1].quote[0].Customer}}</th>
                          <!-- <th>Account</th> -->
                          <th>{{ lan.partners[1].quote[0].date}}</th>
                          <th>{{ lan.partners[1].quote[0].Status}}</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(data, index) in quotesLost"
                        :key="data.quote_id"
                        @click="edit(data.quote_id)"
                        class="pointer"
                      >
                        <tr v-if="data.status == 'Lost'">
                          <td @click="edit(data.quote_id)">{{ data.quote_id }}</td>
                          <td v-if="
                            data.customer_account_detail && data.customer_account_detail.length">
                             <b>{{ data.customer_account_detail[0].name }}</b>
                          </td>
                          <td v-else></td>
                          <!-- <td>{{ data.account_detail.name }}</td> -->
                          <td>{{ data.date }}</td>
                          <td>
                            <span
                              class="badge light"
                              :class="
                                data.status == 'Lost'
                                  ? 'badge-danger': ''
                              "
                              >{{ data.status == 'Lost' ? lan.Status.Lost : data.status }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!value && quotesDenied.length" class="col-xl-12">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h4 class="mb-0 fs-20 text-black">{{ lan.partners[1].quote[0].Accepted_Denied}}</h4>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>{{lan.partners[0].dashboard[0].Quote}} Id</th>
                          <th>{{ lan.partners[1].quote[0].Customer}}</th>
                          <!-- <th>Account</th> -->
                          <th>{{ lan.partners[1].quote[0].date}}</th>
                          <th>{{ lan.partners[1].quote[0].Status}}</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(data, index) in quotesDenied"
                        :key="data.quote_id"
                        @click="edit(data.quote_id)"
                        class="pointer"
                      >
                        <tr v-if="data.status == 'Denied'">
                          <td>{{ data.quote_id }}</td>
                          <td v-if="
                            data.customer_account_detail && data.customer_account_detail.length">
                             <b>{{ data.customer_account_detail[0].name }}</b>
                          </td>
                          <td v-else></td>
                          <!-- <td>{{ data.account_detail.name }}</td> -->
                          <td>{{ data.date }}</td>
                          <td>
                            <span
                              class="badge light"
                              :class="
                                data.status == 'Denied'
                                  ? 'badge-danger': 'badge-success'
                              "
                              >{{ data.status == 'Denied' ? lan.Status.Denied : data.status }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="lds-ellipsis" v-show="value">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <preliminaryBooking :apiData="preliminaryBookingData" @callQuote="getQuotes"/>

    </div>
    <!--**********************************
    Content body end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from 'vuex'
import preliminaryBooking from "../../../components/preliminaryBookingComponent";

export default {
  name: "Partner Quotes",
  components: {preliminaryBooking},
  data() {
    return {
      quoteDetails: "",
      quotesNew: [],
      quotesSubmitted: [],
      quotesAccepted: [],
      quotesLost: [],
      quotesDenied: [],
      value: true,
      showPreliminaryBookingModal: false,
      preliminaryBookingData: null,
      modalOpen: false,
      loading: false,

    };
  },

  computed:{
    ...mapState({
      PreBookDetails: (state) => state.priliminaryBooking
    }),
    ...mapGetters({
      preBookDetails: 'getPreliminaryBooking'
    }),
    lan(){
      return this.$store.getters.whichLanguage
    }
  },


  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isPartner) {
  //     this.AddToast("You can't access Partner Portal", " Warning ", "warning_1");
  //     setTimeout(() => {
  //       alert('First logout then Login partner portal.')
  //     this.$router.back()
  //     }, 3000);
  //   }
  // },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     this.getQuotes()
  },
  methods: {
    getQuotes(){
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(axios.defaults.baseURL + "new-quotes/" + user.company, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        this.quoteDetails = res.data.data;

        this.quotesNew.length = 0;
        this.quotesSubmitted.length = 0;
        this.quotesAccepted.length = 0;
        this.quotesLost.length = 0;
        this.quotesDenied.length = 0;

        res.data.data.forEach(element => {
          if(element.status == "New" || element.status == "Saved" || element.status == "Preliminary Booking")this.quotesNew.push(element)
          else if(element.status == "Submitted" || element.status == "Preliminary Booked" || element.status == "Preliminary Cancelled")this.quotesSubmitted.push(element)
          else if(element.status == "Accepted") this.quotesAccepted.push(element)
          else if(element.status == "Lost") this.quotesLost.push(element)
          else if(element.status == "Denied") this.quotesDenied.push(element)
        });
        this.value = false;
      })
      .catch((err) => {
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
    },
    openModal() {
      this.modalOpen = true;
      this.loading = true;

      // Simulate API call
      setTimeout(() => {
        // Replace this with your actual API call
        this.apiData = "Data loaded from API";
        this.loading = false;
      }, 2000);
    },
    closeModal() {
      this.modalOpen = false;
    },
    openPreliminaryBooking(data){
      console.log('data inside- open-preliminary-booking', data);
      this.preliminaryBookingData = null
      this.$store.dispatch("GetPreliminaryBookingByID",{data: data, comeFrom: 'quote'}).then((res) => {
        console.log('then geting', res);
        this.preliminaryBookingData = this.preBookDetails;
        $('#priliminaryBookingModal').modal("show");
      })
    },
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({
        name: "Partner Edit Quotes",
        params: { id: id },
      });
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
