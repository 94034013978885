<template>
  <div>
    <!--**********************************
    Main wrapper start
  ***********************************-->
    <div id="main-wrapper">
      <!-- Edit Email Template -->
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal-989"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="max-width: 1200px">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ lan.admin[22].Email[0].Edit_Email }} {{ language }}
              </h5>
              <li class="nav-item dropdown notification_dropdown mr-4">
              <a href="javascript:void(0)" role="button" data-toggle="dropdown">
                <a
                  v-if="language == 'English'"
                  class="nav-link ai-icon d-flex bg-orange"
                  href="javascript:void(0)"
                >
                  <img
                    src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
                    class="flag"
                    alt=""
                  />
                  <span class="pl-2 color">En</span>
                </a>
                <a
                  v-else-if="language == 'Swedish'"
                  class="nav-link ai-icon d-flex bg-orange"
                  href="javascript:void(0)"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/1200px-Flag_of_Sweden.svg.png"
                    class="flag"
                    alt=""
                  />
                  <span class="pl-2 color">Sw</span>
                </a>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right p-0"
                style="min-width: 190px"
              >
                <div id="dlab_W_Notification1" class="widget-media p-3">
                  <ul class="timeline ml-4">
                    <li>
                      <div
                        class="timeline-panel"
                        @click="switchLanguag('English')"
                      >
                        <a
                          class="nav-link ai-icon d-flex bg-orange"
                          href="javascript:void(0)"
                        >
                          <img
                            src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
                            class="flag"
                            alt=""
                          />
                          <span class="pl-3 color">English</span>
                        </a>
                      </div>

                      <div
                        class="timeline-panel"
                        @click="switchLanguag('Swedish')"
                      >
                        <a
                          class="nav-link ai-icon d-flex bg-orange"
                          href="javascript:void(0)"
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/1200px-Flag_of_Sweden.svg.png"
                            class="flag"
                            alt=""
                          />
                          <span class="pl-3 color">Swedish</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-md-4 p-3" v-if="emailData.partner_email_body != ''">
              <div class="input">
                <span class="mr-3">{{ lan.admin[22].Email[0].Subject }}:</span>
                <input style="width: 40%" type="text" v-model="subject" />
                <span class="ml-3 mr-3">BCC:</span>
                <input style="width: 40%" type="text" v-model="bcc_email" />
              </div>

              <ckeditor
                :editor="editor"
                v-model="partner_email_body"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="modal-footer">
              <button
                id="email_template_close"
                type="button"
                class="search-btn up-btn mb-0"
                data-dismiss="modal"
              >
                {{ lan.admin[22].Email[0].Close }}
              </button>
              <button
                type="button"
                class="filter-btn up-btn mb-0"
                @click="save()"
              >
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span v-show="loadingS">
                 Send Invitation</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--**********************************
        Content body end
    ***********************************-->
    </div>
    <!--**********************************
    Main wrapper end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import _ from 'lodash';

export default {
  name: "Email Template",
  props:{
    emailData:{
      type: [String, Object, Array],
    }
  },
  data() {
    return {
      value: true,
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      getId: "",
      BodyContent: "",
      partner_email_body: "",
      subject: "",

      loading: false,
      loadingS: true,
      props_emailData: this.emailData,
      bcc_email: '',
      language: 'English'
    };
  },
  watch:{
    emailData(newValue, oldValue){
      console.log('watch newValue', newValue);
      console.log('watch oldValue', oldValue);
      console.log('watch emailData', emailData);
    },
    // props_emailData(newValue, oldValue){
    //   console.log('watch newValue 2', newValue);
    //   console.log('watch oldValue 2', oldValue);
    //   console.log('watch props_emailData', this.props_emailData);
    // }
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);
    console.log('mount emailData---', this.emailData);
    this.$watch(
      () => ({
        props_emailData: this.emailData,
      }),
      (newValues, oldValues) => {
        console.log('newValues----!_---!_-', newValues);
        
        this.getId = newValues?.props_emailData?.emailContent?.id
        this.language = newValues?.props_emailData?.data?.partner_venue_name?.default_language == 'en' ? "English" : "Swedish"
        this.subject = newValues?.props_emailData?.emailContent?.subject
        this.partner_email_body = newValues?.props_emailData?.emailContent?.body
        this.bcc_email = newValues?.props_emailData?.emailContent?.bcc_email
        this.switchLanguag(this.language)


        // do whatever you want with the new and old values
      },
      { deep: true }
    );
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },

  methods: {
    switchLanguag(Language) {
      this.language = Language;
      console.log('Language-!!-', Language);
      console.log('emailData-!!-', this.emailData);
      this.subject = Language == 'English' ? this.emailData.emailContent.subject : this.emailData.emailContent.sw_subject
      this.partner_email_body = Language == 'English' ? this.emailData.emailContent.body : this.emailData.emailContent.sw_body
    },
    save() {
      console.log('save- his.getId--', this.getId);
      this.$emit("email-updated", [1,this.partner_email_body,this.bcc_email, this.subject]);
      document.getElementById('email_template_close').click()
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader-show {
  display: none;
}
</style>
