<template>
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0">
        <div class="container-fluid">
          <div
            class="form-head  mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">{{ lan.Opportunities}}</h2>

            <div class="d-flex justify-content-end mt-3">
              <router-link to="/add-selfopportunity"
                ><button class="filter-btn">
                  {{ lan.Create_new_opportunities}}
                </button></router-link
              >
            </div>
          </div>
          <div class="row mar-bo">
            <div class="col-xl-12">
              <div class="card">
                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0 justify-content-end"
                >
                  <div class="button justify-content-end d-flex">
                    <!-- <a href="#"><button class="search-btn">Search</button></a> -->
                    <input
                      type="search"
                      name="search"
                      class="init new-init ml-3 w-new"
                      placeholder="Search"
                      v-model="search"
                    />
                    <div class="drop-down">
                      <button
                        class="filter-btn mb-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                      {{ lan.Filter_view }}
                      </button>
                      <div
                        class="dropdown-menu mt-3"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="open"
                            value="Open"
                            v-model="filterOptions.open"
                          />
                          <label for="open"> {{ lan.Open }}</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="won"
                            value="Won"
                            v-model="filterOptions.won"
                          />
                          <label for="won">{{ lan.Won }}</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                            <input
                              type="checkbox"
                              class="mr-3 sameInput"
                              id="closed"
                              value="Lost"
                              v-model="filterOptions.lost"
                            />
                            <label for="closed"> {{ lan.Closed }}</label><br />
                        </a>
                        <!-- <a class="dropdown-item" href="#">
                          <input
                            type="radio"
                            id="html1"
                            name="fav_language1"
                            value="Closed"
                            v-model="search"
                          />
                          <label for="vehicle1">{{ lan.Closed }}</label><br />
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body tab-content">
                  <span class="p-3" >Total: <strong>( {{ All_Opportunity_Details.length }} )</strong></span>
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>{{lan.Account}}</th>
                            <th>{{lan.Note}}</th>
                            <th>{{lan.Date}}</th>
                            <th>{{lan.Contact}}</th>
                            <th>{{lan.Phone}}</th>
                            <th>{{lan.Quotes}}</th>
                            <th>{{lan.Status}}</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            All_Opportunity_Details && All_Opportunity_Details.length
                          "
                        >
                          <tr
                            v-for="data in oppo_filtered"
                            :key="data.opportunity_id"
                            @click="edit(data.opportunity_id)"
                            class="pointer"
                            :style="data.status == 'Won' ? `background-color: #ECFAE4` : data.status == 'Lost' ? `background-color: #FFEFEE` : ''"
                          >
                            <td  v-if="data.seen == 0 ? true : false"><i class="fa-solid fa-eye"></i></td>
                            <td  v-if="data.seen == 1 ? true : false"><i class="fa-sharp fa-solid fa-eye-slash"></i></td>
                            <td>{{ data.account_detail.name }}</td>
                            <td v-if="data.note_detail && data.note_detail.length">
                              <span
                                v-for="(note, index) in data.note_detail"
                                :key="index"
                                >{{ note.subject }},</span
                              >
                            </td>
                            <td v-else>0</td>
                            <td>{{ data.date }}</td>
                            <td v-if="data.contact_detail != null" >
                              {{ data.contact_detail.first_name }}
                              {{ data.contact_detail.last_name }}
                            </td>
                            <td v-else></td>
                            <!-- <td>89456132164</td> -->
                            <td>{{ data.contact_detail.phone }}</td>
                            <td>{{ data.submitted_quotes_count }} / {{ data.total_quotes_count }}</td>
                            <td>
                              <span
                                class="badge light badge"
                                :class="
                                  data.status == 'Open'
                                    ? 'badge-info' :
                                    data.status == 'New' ?
                                    'badge-success' : data.status == 'Lost' ? 'badge-danger' :'badge-success'
                                "
                                >{{ data.status == 'Open' ? lan.Open : data.status == 'Won' ? lan.Won : data.status == 'Lost' ? lan.Lost : data.status =='New' ? lan.New : data.status}}</span
                              >
                            </td>
                          </tr>
                        </tbody>
                        <div v-if="totalAPICalls != 0" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
  </div>
</template>
<script>
import axios from "axios";
import { mapState } from 'vuex'
// @ is an alias to /src

export default {
  name: "Opportunity",
  props: {
    msg: String,
  },
  data() {
    return {
      opportunitiesDetails: "",
      value: true,
      search: "",
      filterOptions: {
        open: false,
        won: false,
        lost: false
      },
    };
  },
  computed: {

    ...mapState({
      All_Opportunity_Details: (state) => state.editOpportunity.all_Opportunity_Details,
      totalAPICalls: (state) => state.apiProgress.totalAPICalls,
    }),

    oppo_filtered() {
      // var self = this;
      let filteredData = this.All_Opportunity_Details;
      // Apply filter based on status
      if (this.filterOptions.open || this.filterOptions.won || this.filterOptions.lost) {
        filteredData = filteredData.filter(opportunity => {
          if (this.filterOptions.open && opportunity.status === 'Open') return true;
          if (this.filterOptions.won && opportunity.status === 'Won') return true;
          if (this.filterOptions.lost && opportunity.status === 'Lost') return true;
          return false;
        });
      }
      // Apply search filter
      if (this.search.trim() !== '') {
        const searchTerm = this.search.toLowerCase();
        filteredData = filteredData.filter(opportunity => 
            (opportunity?.account_detail && opportunity?.account_detail?.name && opportunity?.account_detail?.name?.toLowerCase().includes(searchTerm)) ||
            (opportunity?.contact_detail && opportunity?.contact_detail?.phone && opportunity?.contact_detail?.phone?.toLowerCase().includes(searchTerm)) ||
            (opportunity?.contact_detail && opportunity?.contact_detail?.first_name && opportunity?.contact_detail?.first_name?.toLowerCase().includes(searchTerm)) ||
            (opportunity?.contact_detail && opportunity?.contact_detail?.last_name && opportunity?.contact_detail?.last_name?.toLowerCase().includes(searchTerm)) ||
            (opportunity?.status && opportunity?.status?.toLowerCase().includes(searchTerm))
        );
      }

      return filteredData;
    },

    lan(){
      return this.$store.getters.whichLanguage.admin[3].Opportunity[0]
    }
  },
  created(){
    this.$store.dispatch("Get_Opportunites")

  },
  methods: {
    toast(type){
      this.$store.commit("addToast", {
      title: "two !",
      type: type,
      message: "It looks like you have successfully set up Vuex.",
    });
    },
    dismissToast(title) {
        this.$store.commit('clearToast', title);
      },
    edit(id) {
      // alert(localStorage.getItem("token"))
      axios
      .post(axios.defaults.baseURL + "eye-open/" + id ,  {
        
      }, {headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }})
        

      localStorage.setItem("editID", id);
      this.$router.push({
          name: "Edit Opportunity",
          params: { id: id },
        });
    },
  
  },
};
</script>
